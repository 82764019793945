import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    MENSAGEM_ENVIO: 'Enviar Mensagens',
    MENSAGEM_GERENCIAR: 'GerenciarMensagens',
}

export default [
    {
        name: ROUTER_NAMES.MENSAGEM_ENVIO,
        path: '/mensagem',
        meta: {
            menu: MENUS.SISTEMA,
            searchAlias: 'Mensagens',
            searchDescription: 'Envie mensagens para seus alunos',
            searchTerms: ['mensagens'],
            hasRole: 'role/mensagens'
        },
        component: () => import('@/views/Mensagens')
    }/*,
    {
        name: ROUTER_NAMES.MENSAGEM_GERENCIAR,
        path: '/gerenciarMensagens',
        meta: {
            menu: MENUS.SISTEMA,
            searchAlias: 'Mensagens',
            searchDescription: 'Gerenciar mensagens para seus alunos',
            searchTerms: ['mensagens'],
            hasRole: 'role/mensagens'
        },
        component: () => import('@/views/GerenciarMensagens')
    },*/
]