import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    CONTRATO: 'Modificacao'
}

export default [
    {
        name: ROUTER_NAMES.CONTRATO,
        path: '/modificacao',
        meta: {
            menu: MENUS.FUNCIONALIDAES,
            title: 'Modificação',
            searchAlias: 'Modificação',
            searchDescription: 'Veja todas as Modificacões cadastradas',
            searchTerms: ['modificacao'],
            hasRole: 'role/modificacao'
        },
        component: () => import('@/views/Modificacao')
    },
]