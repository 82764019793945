import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    CONTRATO: 'Contrato'
}

export default [
    {
        name: ROUTER_NAMES.CONTRATO,
        path: '/contrato',
        meta: {
            menu: MENUS.FUNCIONALIDAES,
            title: 'Contrato',
            searchAlias: 'Contrato',
            searchDescription: 'Veja todas os Contratos cadastrados',
            searchTerms: ['contrato'],
            hasRole: 'role/contrato'
        },
        component: () => import('@/views/Contrato')
    },
]