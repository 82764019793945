import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    CONTRATADO: 'Pagamento'
}

export default [
    {
        name: ROUTER_NAMES.CONTRATADO,
        path: '/pagamento',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Pagamento',
            searchAlias: 'Pagamento',
            searchDescription: 'Veja todas os Pagamento cadastrados',
            searchTerms: ['pagamento'],
            hasRole: 'role/pagamento'
        },
        component: () => import('@/views/Pagamento')
    },
]