import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    GRUPOTIPOMODIFICACAO: 'Agrupamento Tipo Modificação'
}

export default [
    {
        name: ROUTER_NAMES.GRUPOTIPOMODIFICACAO,
        path: '/grupo-tipo-modificacao',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Agrupamento Tipo Modificação',
            searchAlias: 'Agrupamento',
            searchDescription: 'Veja todas os Agrupamentos de Tipo Modificação cadastrados',
            searchTerms: ['Agrupamento','Tipo Modificação'],
            hasRole: 'role/grupoTipoModificacao'
        },
        component: () => import('@/views/GrupoTipoModificacao')
    },
]