import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    MODALIDADE: 'Modalidade'
}

export default [
    {
        name: ROUTER_NAMES.MODALIDADE,
        path: '/modalidade',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Modalidade',
            searchAlias: 'Modalidade',
            searchDescription: 'Veja todas as Modalidade cadastradas',
            searchTerms: ['Modalidade'],
            hasRole: 'role/modalidade'
        },
        component: () => import('@/views/Modalidade')
    },
]