import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    TIPOMODIFICACAO: 'Tipo de Modificação'
}

export default [
    {
        name: ROUTER_NAMES.TIPOMODIFICACAO,
        path: '/tipo-modificacao',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Tipo de Modificação',
            searchAlias: 'Tipo de Modificação',
            searchDescription: 'Veja todas os Tipo de Modificação cadastradas',
            searchTerms: ['Tipo','Modificação'],
            hasRole: 'role/tipoModificacao'
        },
        component: () => import('@/views/TipoModificacao')
    },
]