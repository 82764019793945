import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    TIPOCONTRATO: 'Item Contrato'
}

export default [
    {
        name: ROUTER_NAMES.TIPOCONTRATO,
        path: '/item-contrato',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Item Contrato',
            searchAlias: 'ItemContrato',
            searchDescription: 'Veja todos os Itens de Contrato cadastrados',
            searchTerms: ['ItemContrato'],
            hasRole: 'role/itemContrato'
        },
        component: () => import('@/views/ItemContrato')
    },
]