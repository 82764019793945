import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import device from 'vue-device-detector-js'
import './plugins/axios';
import './plugins/validators';
import './plugins/toastification';
import './plugins/async-computed';
import './plugins/router-sync';
import './plugins/mask';
import './plugins/moment';
import './plugins/sweet-alert';
import './plugins/utils';
import './plugins/shortkey';
import {findAcessoPorRecursoPerfil} from "./api/gestaoAcesso";

Vue.use(device);

Vue.config.productionTip = process.env.NODE_ENV === 'production';

// Método global que você deseja adicionar
Vue.mixin({
  methods: {
    //metodo global para retornar permissão em um determinado recurso e perfil
    async getPermissaoPorItemPerfil(item, perfil) {
      try {
        const page = await findAcessoPorRecursoPerfil(item, perfil);
        let tamanhoArray = page.dados.length;
        return (tamanhoArray > 0 ? true : false);
      } catch (err) {
        return false;
      }
    },
    isProduction() {
      return process.env.NODE_ENV === "production"
    }, 
    ambiente() {
      return process.env.VUE_APP_AMBIENTE
    },   
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
