import {MENUS} from "../menu";

export const ROUTER_NAMES = {
    TIPOCONTRATO: 'Tipo de Contrato'
}

export default [
    {
        name: ROUTER_NAMES.TIPOCONTRATO,
        path: '/tipo-contrato',
        meta: {
            menu: MENUS.CADASTRO,
            title: 'Tipo de Contrato',
            searchAlias: 'TipoContrato',
            searchDescription: 'Veja todas os Tipos de Contrato cadastrados',
            searchTerms: ['TipoContrato'],
            hasRole: 'role/tipoContrato'
        },
        component: () => import('@/views/TipoContrato')
    },
]